import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, Button, Flex, Heading } from 'rebass/styled-components'
import Item from '../components/rightSideBar/Item'
import SearchSelect from '../components/selects/SearchSelect'
import YearSelect from '../components/selects/YearSelect'
import MakeSelect from '../components/selects/MakeSelect'
import ModelSelect from '../components/selects/ModelSelect'
import VariantSelect from '../components/selects/VariantSelect'
import EditableField from '../components/forms/EditableField'
import Select from 'react-select'
import { setPageCharacteristics } from '../system/redux/reducers/pagebuilder'
import { saveToggle } from '../system/redux/reducers/savehandling'
import styled from 'styled-components'
import { isGrantedAccess } from '../helpers/Permissions'

const Checkbox = styled(Box)`
    display: inline-block;
    height: 1rem;
    width: 1rem;
    border-radius: 0.25rem;
    margin-right: 0.5rem;
    cursor: pointer;

    &,
    &.checked:hover {
        background-color: ${(props) => props.theme.colors.greyDark};
    }

    &:hover,
    &.checked {
        background-color: ${(props) => props.theme.colors.red};
    }

    &.inactive {
        pointer-events: none;
    }
`

const VisibilityCheckbox = ({ active = true, value = false, onToggle }) => {
    return (
        <Checkbox
            className={`${value ? 'checked' : ''} ${active ? '' : 'inactive'}`}
            onClick={() => onToggle(!value)}
        />
    )
}

const LockableTextInput = ({
    editing = false,
    value,
    onChange,
    placeholder = '',
    type = 'text',
}) => {
    return editing ? (
        <EditableField
            width='initial'
            type={type}
            min='0'
            variant='contentEditableInput'
            pl='0'
            value={value}
            placeholder={placeholder}
            onChange={onChange}
        />
    ) : (
        value || 'None'
    )
}

const LockableSelectInput = ({
    defaultValue,
    editing = false,
    value,
    onChange,
    placeholder,
    options,
}) => {
    return editing ? (
        <Select
            options={options}
            defaultValue={defaultValue}
            onChange={onChange}
        />
    ) : (
        value || 'None'
    )
}

const Characteristics = ({
    dispatch,
    profileId,
    characteristics,
    permissionsTier,
}) => {
    const [edit, setEdit] = useState(false)
    const onInputChange = (key, value) => {
        const detailsData = JSON.stringify({ [key]: value })
        dispatch(setPageCharacteristics(detailsData))
        dispatch(saveToggle(true))
    }

    const onInputChangeNested = (parentKey, subKeys, value) => {
        // shallow copy of parent object
        const data = {}
        data[parentKey] = { ...characteristics[parentKey] }

        // iterate through subkeys, keeping values along the way, and then update the lowest child value
        let currParent = data[parentKey]
        subKeys.forEach((key, index) => {
            if (index === subKeys.length - 1) {
                currParent[key] = value
            } else {
                currParent[key] = { ...currParent[key] }
                currParent = currParent[key]
            }
        })

        dispatch(setPageCharacteristics(JSON.stringify(data)))
        dispatch(saveToggle(true))
    }

    useEffect(() => {
        if (!profileId) {
            setEdit(true)
        }
    }, [profileId])

    const mileageOptions = [
        {
            value: 'Kilometres',
            label: 'Kilometres',
        },
        {
            value: 'Miles',
            label: 'Miles',
        },
    ]

    const defaultMileage = characteristics.mileage?.unit
        ? {
              value: characteristics.mileage.unit,
              label: characteristics.mileage.unit,
          }
        : {
              value: 'Miles',
              label: 'Miles',
          }

    return (
        <>
            <Flex justifyContent='space-between'>
                <Heading pb='sm' variant='h4' as='h4'>
                    Identity
                </Heading>
                <Button variant='primaryTiny' onClick={() => setEdit(!edit)}>
                    {!edit ? 'Edit' : 'Lock'}
                </Button>
            </Flex>
            <Box>
                {isGrantedAccess('diamond', permissionsTier) ? (
                    <Item title='Code'>
                        <LockableTextInput
                            editing={edit}
                            placeholder='Enter Code'
                            value={
                                (characteristics && characteristics.code) || ''
                            }
                            onChange={(event) =>
                                onInputChange('code', event.target.value)
                            }
                        />
                    </Item>
                ) : null}
                <Item title='Year'>
                    <YearSelect active={edit} />
                </Item>
                <Item title='Make'>
                    <MakeSelect active={edit} />
                </Item>
                <Item title='Model'>
                    <ModelSelect active={edit} />
                </Item>
                <Item title='Variant'>
                    <VariantSelect active={edit} />
                </Item>
                <Item title='Chassis'>
                    <Flex alignItems='center'>
                        <VisibilityCheckbox
                            active={edit}
                            value={characteristics?.chassis?.visible || false}
                            onToggle={(value) =>
                                onInputChangeNested(
                                    'chassis',
                                    ['visible'],
                                    value
                                )
                            }
                        />
                        <Box flexGrow='1'>
                            <SearchSelect
                                active={edit}
                                source='characteristics.chassis.number'
                                target='chassis'
                                makeFilter={characteristics?.model ?? false}
                                isClearable={true}
                                onSelect={(value) =>
                                    onInputChangeNested(
                                        'chassis',
                                        ['number'],
                                        value?.value ?? ''
                                    )
                                }
                            />
                        </Box>
                    </Flex>
                </Item>
                <Item title='Engine'>
                    <Flex alignItems='center'>
                        <VisibilityCheckbox
                            active={edit}
                            value={characteristics?.engine?.visible || false}
                            onToggle={(value) =>
                                onInputChangeNested(
                                    'engine',
                                    ['visible'],
                                    value
                                )
                            }
                        />
                        <LockableTextInput
                            editing={edit}
                            placeholder='Enter Engine Number'
                            value={characteristics?.engine?.number || ''}
                            onChange={(event) =>
                                onInputChangeNested(
                                    'engine',
                                    ['number'],
                                    event.target.value
                                )
                            }
                        />
                    </Flex>
                </Item>
                <Item title='Reg'>
                    <Flex alignItems='center'>
                        <VisibilityCheckbox
                            active={edit}
                            value={
                                characteristics?.registration?.number
                                    ?.visible || false
                            }
                            onToggle={(value) =>
                                onInputChangeNested(
                                    'registration',
                                    ['number', 'visible'],
                                    value
                                )
                            }
                        />
                        <LockableTextInput
                            editing={edit}
                            placeholder='Enter Registration'
                            value={
                                characteristics?.registration?.number
                                    ?.current || ''
                            }
                            onChange={(event) =>
                                onInputChangeNested(
                                    'registration',
                                    ['number', 'current'],
                                    event.target.value
                                )
                            }
                        />
                    </Flex>
                </Item>
                <Item title='Location'>
                    <Flex alignItems='center'>
                        <VisibilityCheckbox
                            active={edit}
                            value={characteristics?.location?.visible || false}
                            onToggle={(value) =>
                                onInputChangeNested(
                                    'location',
                                    ['visible'],
                                    value
                                )
                            }
                        />
                        <LockableTextInput
                            editing={edit}
                            placeholder='Enter Location'
                            value={characteristics?.location?.value || ''}
                            onChange={(event) =>
                                onInputChangeNested(
                                    'location',
                                    ['value'],
                                    event.target.value
                                )
                            }
                        />
                    </Flex>
                </Item>
                <Item title='File Loc'>
                    <Flex alignItems='center'>
                        <LockableTextInput
                            editing={edit}
                            placeholder='Enter File Location'
                            value={characteristics?.fileLocation?.value || ''}
                            onChange={(event) =>
                                onInputChangeNested(
                                    'fileLocation',
                                    ['value'],
                                    event.target.value
                                )
                            }
                        />
                    </Flex>
                </Item>
                <Item title='Mileage'>
                    <Flex alignItems='center'>
                        <LockableTextInput
                            editing={edit}
                            type='number'
                            placeholder='Enter Vehicle Mileage'
                            value={characteristics?.mileage?.value || ''}
                            onChange={(event) =>
                                onInputChangeNested(
                                    'mileage',
                                    ['value'],
                                    event.target.value
                                )
                            }
                        />
                    </Flex>
                </Item>
                <Item title='Mileage Unit'>
                    <LockableSelectInput
                        editing={edit}
                        defaultValue={defaultMileage}
                        options={mileageOptions}
                        value={characteristics?.mileage?.unit}
                        onChange={(event) =>
                            onInputChangeNested(
                                'mileage',
                                ['unit'],
                                event.value
                            )
                        }
                    />
                </Item>
            </Box>
        </>
    )
}

function mapStateToProps(state) {
    return {
        auth: state.Auth.userVerified,
        profileId: state.Pagebuilder.profile.id,
        characteristics:
            state.Pagebuilder.extras &&
            state.Pagebuilder.extras.characteristics,
        permissionsTier: state.User.permissionsTier,
    }
}

export default connect(mapStateToProps)(Characteristics)
